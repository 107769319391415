<template>
  <div class="container">
    <div class="form-card">
      <div class="card-head">
        <h4 class="card-title">Uygulama Ekle</h4>
      </div>
      <div class="card-body mb-20">
        <h4 class="card-sm-title">Store</h4>
        <div class="row">
          <div class="col-md-4">
            <TextTemplate
              label="Store Url"
              :value="formData.ecommerce_store.store_url"
              keyName="formData.ecommerce_store.store_url"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ecommerce_store.store_url.$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ecommerce_store.store_url.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Security Privacy Policy Url"
              :value="formData.ecommerce_store.security_privacy_policy_url"
              keyName="formData.ecommerce_store.security_privacy_policy_url"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ecommerce_store.security_privacy_policy_url.$errors
                  .length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ecommerce_store
                .security_privacy_policy_url.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Support Url"
              :value="formData.ecommerce_store.support_url"
              keyName="formData.ecommerce_store.support_url"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ecommerce_store.support_url.$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ecommerce_store.support_url.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Contact E-mail"
              :value="formData.ecommerce_store.contact_email"
              keyName="formData.ecommerce_store.contact_email"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ecommerce_store.contact_email.$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ecommerce_store.contact_email.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Ecommerce Platform"
              :value="formData.ecommerce_store.ecommerce_platform"
              keyName="formData.ecommerce_store.ecommerce_platform"
              type="selectID"
              :options="ecommerce_platform_list"
              @onChangeText="handleText"
              :className="
                v$.formData.ecommerce_store.ecommerce_platform.$errors.length >
                0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ecommerce_store.ecommerce_platform
                .$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <div
              class="form-group"
              :class="
                v$.formData.ecommerce_store.customer.$errors.length > 0
                  ? 'error'
                  : ''
              "
            >
              <label for="">Müşteri</label>
              <Multiselect
                v-model="formData.ecommerce_store.customer"
                :options="customer_list"
                label="name"
                track-by="name"
                placeholder=""
                selectLabel=""
                selectedLabel=""
                deselectLabel=""
              ></Multiselect>
            </div>
            <span
              v-for="error in v$.formData.ecommerce_store.customer.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
        </div>
      </div>
      <div class="card-body mb-20">
        <h4 class="card-sm-title">İdeasoft</h4>
        <div class="row">
          <div class="col-md-4">
            <TextTemplate
              label="İdeasoft Panel Kullanıcı Adı"
              :value="formData.ideasoft_store_attributes.control_panel_username"
              keyName="formData.ideasoft_store_attributes.control_panel_username"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ideasoft_store_attributes.control_panel_username
                  .$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ideasoft_store_attributes
                .control_panel_username.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="İdeasoft Panel Kullanıcı Şifre"
              :value="formData.ideasoft_store_attributes.control_panel_password"
              keyName="formData.ideasoft_store_attributes.control_panel_password"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ideasoft_store_attributes.control_panel_password
                  .$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ideasoft_store_attributes
                .control_panel_password.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="İdeasoft Panel OAtuh CID"
              :value="formData.ideasoft_store_attributes.oauth_client_id"
              keyName="formData.ideasoft_store_attributes.oauth_client_id"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ideasoft_store_attributes.oauth_client_id.$errors
                  .length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ideasoft_store_attributes
                .oauth_client_id.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="İdeasoft Panel OAtuh CSECRET"
              :value="formData.ideasoft_store_attributes.oauth_client_secret"
              keyName="formData.ideasoft_store_attributes.oauth_client_secret"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ideasoft_store_attributes.oauth_client_secret
                  .$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ideasoft_store_attributes
                .oauth_client_secret.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="İdeasoft Store ID"
              :value="formData.ideasoft_store_attributes.ideasoft_store_id"
              keyName="formData.ideasoft_store_attributes.ideasoft_store_id"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ideasoft_store_attributes.ideasoft_store_id.$errors
                  .length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ideasoft_store_attributes
                .ideasoft_store_id.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="İdeasoft Admin Panel Url"
              :value="formData.ideasoft_store_attributes.control_panel_url"
              keyName="formData.ideasoft_store_attributes.control_panel_url"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ideasoft_store_attributes.control_panel_url.$errors
                  .length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ideasoft_store_attributes
                .control_panel_url.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
        </div>
      </div>
      <div class="card-body mb-20">
        <h4 class="card-sm-title">Subsription</h4>
        <div class="row">
          <div class="col-md-4">
            <TextTemplate
              label="Başlangıç Tarihi"
              :value="formData.subscription.start_date"
              keyName="formData.subscription.start_date"
              type="date"
              @onChangeText="handleText"
              :className="
                v$.formData.subscription.start_date.$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.subscription.start_date.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Plan"
              :value="formData.subscription.plan"
              keyName="formData.subscription.plan"
              type="selectID"
              :options="subscription_plan_list"
              @onChangeText="handleText"
              :className="
                v$.formData.subscription.plan.$errors.length > 0 ? 'error' : ''
              "
            />
            <span
              v-for="error in v$.formData.subscription.plan.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
        </div>
      </div>
      <div class="card-body mb-20">
        <h4 class="card-sm-title">App</h4>
        <div class="row">
          <div class="col-md-4">
            <TextTemplate
              label="Ad"
              :value="formData.name"
              keyName="formData.name"
              type="text"
              :className="v$.formData.name.$errors.length > 0 ? 'error' : ''"
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.formData.name.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Yönetici Ad"
              :value="formData.administrative_contact_given_name"
              keyName="formData.administrative_contact_given_name"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.administrative_contact_given_name.$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.administrative_contact_given_name
                .$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Yönetici Soyad"
              :value="formData.administrative_contact_family_name"
              keyName="formData.administrative_contact_family_name"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.administrative_contact_family_name.$errors.length >
                0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.administrative_contact_family_name
                .$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Yönetici Email"
              :value="formData.administrative_contact_email"
              keyName="formData.administrative_contact_email"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.administrative_contact_email.$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.administrative_contact_email.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Yönetici Telefon"
              :value="formData.administrative_contact_mobile_phone"
              keyName="formData.administrative_contact_mobile_phone"
              type="text"
              mask="phone"
              @onChangeText="handleText"
              :className="
                v$.formData.administrative_contact_mobile_phone.$errors.length >
                0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.administrative_contact_mobile_phone
                .$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Firebase PS Key"
              :value="formData.firebase_project_server_key"
              keyName="formData.firebase_project_server_key"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.firebase_project_server_key.$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.firebase_project_server_key.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Version"
              :value="formData.version"
              keyName="formData.version"
              type="text"
              @onChangeText="handleText"
              :className="v$.formData.version.$errors.length > 0 ? 'error' : ''"
            />
            <span
              v-for="error in v$.formData.version.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Kısa Açıklama"
              :value="formData.short_description"
              keyName="formData.short_description"
              type="text"
              @onChangeText="handleText"
            />
          </div>
          <div class="col-md-12">
            <TextTemplate
              label="Uzun Açıklama"
              :value="formData.long_description"
              keyName="formData.long_description"
              type="textarea"
              @onChangeText="handleText"
            />
          </div>
          <div class="col-md-4">
            <UploadTemplate
              @file-committed="handleFileCommit"
              label="Logo"
              itemName="formData.logo"
              :alReadyUploadedFileName="formData.logo"
              :appId="appId"
              :className="v$.formData.logo.$errors.length > 0 ? 'error' : ''"
            />
            <span
              v-for="error in v$.formData.logo.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <UploadTemplate
              @file-committed="handleFileCommit"
              label="JS Inject"
              itemName="formData.js_injection_file"
              :alReadyUploadedFileName="formData.js_injection_file"
              :appId="appId"
            />
          </div>
          <div class="col-md-4">
            <UploadTemplate
              @file-committed="handleFileCommit"
              label="JSON Config"
              itemName="formData.json_config_file"
              :alReadyUploadedFileName="formData.json_config_file"
              :appId="appId"
            />
          </div>
        </div>
      </div>
      <div class="card-body">
        <h4 class="card-sm-title">Platform Seçiniz</h4>
        <div class="tabs">
          <div class="tabs-head">
            <div
              class="tab"
              :class="{ active: activeTab === 0 }"
              @click="setActiveTab(0)"
            >
              IOS
            </div>
            <div
              class="tab"
              :class="{ active: activeTab === 1 }"
              @click="setActiveTab(1)"
            >
              Android
            </div>
          </div>
          <span
            v-for="error in v$.checkPlatforms.$errors"
            :key="error.$uid"
            class="errMsg"
            >{{ error.$message }}</span
          >
          <div class="tabs-container">
            <div class="tab" :class="{ active: activeTab === 0 }">
              <div class="row">
                <div class="col-md-4">
                  <TextTemplate
                    label="Team ID"
                    type="text"
                    :value="formData.ios_attributes.team_id"
                    keyName="formData.ios_attributes.team_id"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.ios_attributes.team_id.$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.team_id.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Account Holder Email"
                    type="text"
                    :value="formData.ios_attributes.account_holder_email"
                    keyName="formData.ios_attributes.account_holder_email"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.ios_attributes.account_holder_email.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .account_holder_email.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Bundle ID"
                    type="text"
                    :value="formData.ios_attributes.bundle_id"
                    keyName="formData.ios_attributes.bundle_id"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.ios_attributes.bundle_id.$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.bundle_id
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Version"
                    type="text"
                    :value="formData.ios_attributes.version"
                    keyName="formData.ios_attributes.version"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.ios_attributes.version.$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.version.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Test Kullanıcı Adı"
                    type="text"
                    :value="formData.ios_attributes.testuser_username"
                    keyName="formData.ios_attributes.testuser_username"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.ios_attributes.testuser_username.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.testuser_username
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Test Kullanıcı Şifre"
                    type="text"
                    :value="formData.ios_attributes.testuser_password"
                    keyName="formData.ios_attributes.testuser_password"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.ios_attributes.testuser_password.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.testuser_password
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Apple Developer Hesabı"
                    :value="formData.ios_attributes.apple_developer_account"
                    keyName="formData.ios_attributes.apple_developer_account"
                    type="selectID"
                    :options="apple_developer_account_list"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.ios_attributes.apple_developer_account.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .apple_developer_account.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Private Key"
                    itemName="formData.ios_attributes.private_key"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.private_key
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.private_key.$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.private_key
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Csr File"
                    itemName="formData.ios_attributes.csr_file"
                    :alReadyUploadedFileName="formData.ios_attributes.csr_file"
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.csr_file.$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.csr_file.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Development Cer"
                    itemName="formData.ios_attributes.development_certificate"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.development_certificate
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.development_certificate.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .development_certificate.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Distribution Cer"
                    itemName="formData.ios_attributes.distribution_certificate"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.distribution_certificate
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.distribution_certificate
                        .$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .distribution_certificate.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="APNS Cer"
                    itemName="formData.ios_attributes.apns_certificate"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.apns_certificate
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.apns_certificate.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.apns_certificate
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Firebase APNS Cer"
                    itemName="formData.ios_attributes.firebase_apns_certificate"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.firebase_apns_certificate
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.firebase_apns_certificate
                        .$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .firebase_apns_certificate.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Development Profile"
                    itemName="formData.ios_attributes.development_provisioning_profile"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.development_provisioning_profile
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes
                        .development_provisioning_profile.$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .development_provisioning_profile.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Ad Hoc Profile"
                    itemName="formData.ios_attributes.ad_hoc_provisioning_profile"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.ad_hoc_provisioning_profile
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.ad_hoc_provisioning_profile
                        .$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .ad_hoc_provisioning_profile.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="App Store Profile"
                    itemName="formData.ios_attributes.app_store_provisioning_profile"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.app_store_provisioning_profile
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.app_store_provisioning_profile
                        .$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .app_store_provisioning_profile.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Firebase Config File"
                    itemName="formData.ios_attributes.ios_firebase_configuration_file"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.ios_firebase_configuration_file
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.ios_firebase_configuration_file
                        .$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .ios_firebase_configuration_file.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
              </div>
            </div>
            <div class="tab" :class="{ active: activeTab === 1 }">
              <div class="row">
                <div class="col-md-4">
                  <TextTemplate
                    label="Developer ID"
                    :value="formData.android_attributes.google_developer_id"
                    keyName="formData.android_attributes.google_developer_id"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.google_developer_id.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes
                      .google_developer_id.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Package Name"
                    :value="formData.android_attributes.package_name"
                    keyName="formData.android_attributes.package_name"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.package_name.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes.package_name
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Version Code"
                    :value="formData.android_attributes.version_code"
                    keyName="formData.android_attributes.version_code"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.version_code.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes.version_code
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Version Name"
                    :value="formData.android_attributes.version_name"
                    keyName="formData.android_attributes.version_name"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.version_name.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes.version_name
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Key Alias"
                    :value="formData.android_attributes.key_alias"
                    keyName="formData.android_attributes.key_alias"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.key_alias.$errors.length >
                      0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes.key_alias
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Keystore Password"
                    :value="formData.android_attributes.keystore_password"
                    keyName="formData.android_attributes.keystore_password"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.keystore_password.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes
                      .keystore_password.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Key Password"
                    :value="formData.android_attributes.key_password"
                    keyName="formData.android_attributes.key_password"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.key_password.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes.key_password
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Test Kullanıcı Adı"
                    :value="formData.android_attributes.testuser_username"
                    keyName="formData.android_attributes.testuser_username"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.testuser_username.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes
                      .testuser_username.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Test Kullanıcı Şifre"
                    :value="formData.android_attributes.testuser_password"
                    keyName="formData.android_attributes.testuser_password"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.testuser_password.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes
                      .testuser_password.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Google Developer Hesabı"
                    :value="
                      formData.android_attributes.google_developer_account
                    "
                    keyName="formData.android_attributes.google_developer_account"
                    type="selectID"
                    :options="google_developer_account_list"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.google_developer_account
                        .$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes
                      .google_developer_account.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="JKS"
                    itemName="formData.android_attributes.java_keystore"
                    :alReadyUploadedFileName="
                      formData.android_attributes.java_keystore
                    "
                    :appId="appId"
                    :className="
                      v$.formData.android_attributes.java_keystore.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes.java_keystore
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Firebase File"
                    itemName="formData.android_attributes.android_firebase_configuration_file"
                    :alReadyUploadedFileName="
                      formData.android_attributes
                        .android_firebase_configuration_file
                    "
                    :appId="appId"
                    :className="
                      v$.formData.android_attributes.java_keystore.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes
                      .android_firebase_configuration_file.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-filled-primary ml-auto mt-20"
        @click="createApp"
      >
        Uygulama Oluştur
      </button>
    </div>
  </div>
  <Error
    @closeModal="doneModalClose"
    v-if="isCloseModal"
    :doneMsg="popupText"
    :doneTitle="popupTitle"
    link="apps"
  />
</template>
<script>
import useValidate from "@vuelidate/core";
import { required, helpers, requiredIf, email } from "@vuelidate/validators";

export default {
  data() {
    return {
      popupText: "",
      popupTitle: "",
      appId: this.$route.params.id,
      activeTab: 1,
      isCloseModal: false,
      numberMask: {
        mask: ["#############################################################"],
        tokens: {
          "#": { pattern: /[0-9]/ },
        },
      },
      httpMask: {
        mask: ["http*://", "http://"],
        tokens: {
          "*": { pattern: /[s]/ },
        },
      },
      versionNumberMask: {
        mask: ["##"],
        tokens: {
          "#": { pattern: /[0-9]/ },
        },
      },
      maskAlphaNumeric: {
        mask: ["#############################################################"],
        tokens: {
          "#": { pattern: /[a-zA-Z0-9]/ },
        },
      },
      v$: useValidate(),
      formData: {
        ecommerce_store: {
          store_url: "",
          security_privacy_policy_url: "",
          support_url: "",
          contact_email: "",
          ecommerce_platform: "",
          customer: "",
        },
        ideasoft_store_attributes: {
          control_panel_username: "",
          control_panel_password: "",
          oauth_client_id: "",
          oauth_client_secret: "",
          ideasoft_store_id: "",
          control_panel_url: "",
        },
        subscription: {
          plan: "",
          start_date: "",
          customer: "",
        },
        name: "",
        administrative_contact_given_name: "Tarkan",
        administrative_contact_family_name: "Acan",
        administrative_contact_email: "tarkan.acan@mapplico.com",
        administrative_contact_mobile_phone: "05552537878",
        firebase_project_server_key: "",
        short_description: "",
        long_description: "",
        logo: "",
        js_injection_file: "",
        json_config_file: "",
        version: "",
        ios_attributes: {
          team_id: "",
          account_holder_email: "",
          bundle_id: "",
          version: "",
          testuser_username: "",
          testuser_password: "",
          apple_developer_account: "",
          private_key: "",
          csr_file: "",
          development_certificate: "",
          distribution_certificate: "",
          apns_certificate: "",
          firebase_apns_certificate: "",
          development_provisioning_profile: "",
          ad_hoc_provisioning_profile: "",
          app_store_provisioning_profile: "",
          ios_firebase_configuration_file: "",
        },
        android_attributes: {
          google_developer_id: "",
          package_name: "",
          version_code: "",
          version_name: "",
          key_alias: "upload",
          keystore_password: "Mm.321321",
          key_password: "Mm.321321",
          testuser_username: "testuser@google.com",
          testuser_password: "Tt321321",
          google_developer_account: "",
          java_keystore: "",
          android_firebase_configuration_file: "",
        },
      },
      checkPlatforms: "1",
      ecommerce_platform_list: [],
      customer_list: [],
      subscription_plan_list: [],
      google_developer_account_list: [],
      apple_developer_account_list: [],
    };
  },

  validations() {
    return {
      checkPlatforms: {
        required: helpers.withMessage(
          "En az 1 platform bilgisi girmelisiniz.",
          required
        ),
        $autoDirty: true,
      },
      formData: {
        name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        administrative_contact_given_name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        administrative_contact_family_name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        administrative_contact_email: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          email: helpers.withMessage(
            "Lütfen geçerli bir e-mail giriniz.",
            email
          ),
          $autoDirty: true,
        },
        administrative_contact_mobile_phone: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        firebase_project_server_key: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        version: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        logo: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        android_attributes: {
          google_developer_id: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          google_developer_account: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          package_name: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          version_code: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          version_name: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          key_alias: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          keystore_password: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          key_password: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          testuser_username: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            email: helpers.withMessage(
              "Lütfen geçerli bir e-mail giriniz.",
              email
            ),
            $autoDirty: true,
          },
          testuser_password: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          java_keystore: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          android_firebase_configuration_file: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
        },
        ios_attributes: {
          team_id: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          account_holder_email: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          bundle_id: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          version: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          testuser_username: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            email: helpers.withMessage(
              "Lütfen geçerli bir e-mail giriniz.",
              email
            ),
            $autoDirty: true,
          },
          testuser_password: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          apple_developer_account: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          private_key: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          csr_file: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          development_certificate: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          distribution_certificate: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          apns_certificate: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          firebase_apns_certificate: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          development_provisioning_profile: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          ad_hoc_provisioning_profile: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          app_store_provisioning_profile: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          ios_firebase_configuration_file: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
        },
        ecommerce_store: {
          contact_email: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            email: helpers.withMessage(
              "Lütfen geçerli bir e-mail giriniz.",
              email
            ),
            $autoDirty: true,
          },
          customer: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          ecommerce_platform: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          security_privacy_policy_url: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          store_url: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          support_url: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
        },
        ideasoft_store_attributes: {
          control_panel_password: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          control_panel_username: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          ideasoft_store_id: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          oauth_client_id: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          oauth_client_secret: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          control_panel_url: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
        },
        subscription: {
          plan: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          start_date: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
        },
      },
    };
  },
  async created() {
    await this.getCustomers().then(
      (customers) => (this.customer_list = customers)
    );

    await this.getEcommercePlatforms().then(
      (platforms) => (this.ecommerce_platform_list = platforms)
    );

    await this.getSubscriptionPlans().then(
      (plans) => (this.subscription_plan_list = plans)
    );

    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Mobil Uygulamalar",
        path: "/apps",
      },
      {
        title: "Mobil Uygulama Ekle",
      },
    ]);
  },
  watch: {
    formData: {
      handler() {
        let checkIos = Object.values(this.formData.ios_attributes).some(
          (val) => val !== ""
        );
        let checkAndroid = Object.values(this.formData.android_attributes).some(
          (val) => val !== ""
        );

        checkAndroid || checkIos
          ? (this.checkPlatforms = "1")
          : (this.checkPlatforms = "");
      },
      deep: true,
    },
    "formData.ecommerce_store.customer": function () {
      if (this.formData.ecommerce_store.customer) {
        this.getGoogleDeveloperAccounts();
        this.getAppleDeveloperAccounts();
        this.formData.subscription.customer = Number(
          this.formData.ecommerce_store.customer.id
        );
      }
    },
  },
  methods: {
    setActiveTab(index) {
      this.activeTab = index;
    },
    doneModalClose(value) {
      this.isCloseModal = value;
    },
    async getEcommercePlatforms() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/ecommerce-platform/")
          .then((response) => {
            let platforms = response.data;
            let ecommerce_platform_list = platforms.map((platform) => ({
              id: platform.id,
              name: platform.name,
            }));
            resolve(ecommerce_platform_list);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getCustomers() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/customer/")
          .then((response) => {
            let customers = response.data;
            let customer_list = customers.map((customer) => ({
              id: customer.id,
              name: customer.name,
            }));
            resolve(customer_list);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getSubscriptionPlans() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/subscription-plan/")
          .then((response) => {
            let plans = response.data;
            let subscription_plan_list = plans.map((plan) => ({
              id: plan.id,
              name: plan.name,
            }));
            resolve(subscription_plan_list);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getGoogleDeveloperAccounts() {
      this.$axios
        .get(
          `/customer/${this.formData.ecommerce_store.customer.id}/developer-account/google/`
        )
        .then((response) => {
          let accounts = response.data;
          this.google_developer_account_list = [];
          accounts.filter((account) => {
            this.google_developer_account_list.push({
              id: account.id,
              name: account.name,
            });
          });
        });
    },
    getAppleDeveloperAccounts() {
      this.$axios
        .get(
          `/customer/${this.formData.ecommerce_store.customer.id}/developer-account/apple/`
        )
        .then((response) => {
          let accounts = response.data;
          this.apple_developer_account_list = [];
          accounts.filter((account) => {
            this.apple_developer_account_list.push({
              id: account.id,
              name: account.name,
            });
          });
        });
    },

    convertBase64(file) {
      return new Promise((resolve, reject) => {
        if (file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const base64String = e.target.result.split(",")[1];
            const resolveBase64WithName = `data:${
              file.type == "" ? "text/plain" : file.type
            };'${file.name}';base64,${base64String}`;
            resolve(resolveBase64WithName);
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        } else {
          reject(new Error("No file provided"));
        }
      });
    },

    async handleUploadedFile(formData, field, postData) {
      let fields = field.split(".");
      if (fields.length > 1) {
        if (formData[fields[0]][fields[1]]) {
          await this.convertBase64(formData[fields[0]][fields[1]]).then(
            (resolveBase64WithName) => {
              postData[fields[0]][fields[1]] = resolveBase64WithName;
            }
          );
        }
      } else {
        if (formData[fields[0]]) {
          await this.convertBase64(formData[fields[0]]).then(
            (resolveBase64WithName) => {
              postData[fields[0]] = resolveBase64WithName;
            }
          );
        }
      }
    },

    removeEmpty(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] && typeof obj[key] === "object") {
          if (Object.keys(obj[key]).length === 0) {
            delete obj[key];
          } else {
            this.removeEmpty(obj[key]);
            if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
            }
          }
        } else if (obj[key] == null || obj[key] === "") {
          delete obj[key];
        }
      });
      return obj;
    },

    async createApp() {
      let postData = JSON.parse(JSON.stringify(this.formData));

      let files = [
        "logo",
        "js_injection_file",
        "json_config_file",
        "android_attributes.java_keystore",
        "android_attributes.android_firebase_configuration_file",
        "ios_attributes.private_key",
        "ios_attributes.csr_file",
        "ios_attributes.development_certificate",
        "ios_attributes.distribution_certificate",
        "ios_attributes.apns_certificate",
        "ios_attributes.firebase_apns_certificate",
        "ios_attributes.development_provisioning_profile",
        "ios_attributes.ad_hoc_provisioning_profile",
        "ios_attributes.app_store_provisioning_profile",
        "ios_attributes.ios_firebase_configuration_file",
      ];

      for (let file of files) {
        await this.handleUploadedFile(this.formData, file, postData);
      }

      const newCustomer = { ...postData.ecommerce_store.customer };

      if (postData.short_description == "")
        postData.short_description = `${postData.name} uygulaması artık cebinizde.`;
      if (postData.long_description == "")
        postData.long_description = `${postData.name} uygulaması artık cebinizde. \n${postData.name} uygulaması ile artık anında; \n- Ürünlerimizi inceleyebilir ve hemen sipariş verebilir \n- Kampanyalardan haberdar olup, faydalanabilirsiniz.`;

      postData.ecommerce_store.customer = newCustomer.id;

      this.removeEmpty(postData);

      this.v$.$touch();
      if (!this.v$.$error) {
        this.$axios
          .post("/mobile-app/", postData)
          .then((response) => {
            this.$router.replace({
              name: "AppEdit",
              params: { id: response.data.id },
            });
          })
          .catch((error) => {
            this.popupText = error.response.data
              ? error.response.data
              : "Bir sorun oluştu tekrar deneyin.";
            this.popupTitle = "İşlem Başarısız!";
            this.isCloseModal = true;
          });
      }
    },

    handleFileCommit(data) {
      let keynames = data.name.split(".");
      let target = this; // Başlangıç noktası, this (genellikle Vue bileşeni)
      this.convertBase64(data.file);
      for (let i = 0; i < keynames.length; i++) {
        if (i === keynames.length - 1) {
          target[keynames[i]] = data.file; // Son adımda data.value'yu atama
        } else {
          if (!target[keynames[i]]) {
            target[keynames[i]] = {}; // Eğer yol henüz tanımlanmamışsa boş bir nesne oluşturma
          }
          target = target[keynames[i]]; // Yolu takip et
        }
      }
    },
    handleText(data) {
      let keynames = data.keyName.split(".");
      let target = this; // Başlangıç noktası, this (genellikle Vue bileşeni)

      for (let i = 0; i < keynames.length; i++) {
        if (i === keynames.length - 1) {
          target[keynames[i]] = data.value; // Son adımda data.value'yu atama
        } else {
          if (!target[keynames[i]]) {
            target[keynames[i]] = {}; // Eğer yol henüz tanımlanmamışsa boş bir nesne oluşturma
          }
          target = target[keynames[i]]; // Yolu takip et
        }
      }
    },
  },
};
</script>
 